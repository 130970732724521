import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import quote_stripe_left from "../images/quote_stripe_left.svg"
import quote_stripe_right from "../images/quote_stripe_right.svg"
import Productblock from "../components/Productblock"

const Pricing = () => {
  return (
    <Layout>
      <Helmet>
        <title>Pricing | InsideHero</title>
        <meta name="title" content="Testing with " />
        <meta
          name="description"
          content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content="Testing with " />
        <meta
          property="og:description"
          content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!"
        />
        <meta
          property="og:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta property="twitter:title" content="Testing with " />
        <meta
          property="twitter:description"
          content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!"
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />
      </Helmet>

      <div className="container">
        <section className="quote_section">
          <div className="quote_content">
            <h1>Request a custom price quote</h1>
            <p>
              Your business is unique. That’s why we’ll help you tailor a
              solution that will help your business succeed.
            </p>
            <img src={quote_stripe_left} alt="" />
          </div>

          <div className="quote_form_section">
            <img src={quote_stripe_right} alt="" />
            <form
              className="qform"
              action="https://getform.io/f/8655ce74-a169-4a0a-a9f2-83cbdeb6ea92"
              method="POST"
            >
              <h4>Request a quote.</h4>
              <input
                className="quote-form_half"
                type="text"
                name="First Name"
                placeholder="First Name"
                required
              />
              <input
                className="quote-form_half float-right"
                type="text"
                name="Last Name"
                placeholder="Last Name"
                required
              />
              <input
                className="quote-form"
                type="text"
                name="Company Name"
                placeholder="Company Name"
                required
              />
              <input
                className="quote-form"
                type="email"
                name="Email Address"
                placeholder="Email Address"
                required
              />
              <input
                className="quote-form"
                type="tel"
                name="Mobile"
                placeholder="Mobile"
                required
              />
              <button type="submit" className="btn btn-main">
                Get a Quote
              </button>
            </form>
          </div>
        </section>
      </div>

      <section className="pricing_products_section">
        <div className="container">
          <h2>Not quite ready yet? Learn more about our products:</h2>
          <Productblock />
        </div>
      </section>
    </Layout>
  )
}

export default Pricing
